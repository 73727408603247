<template>
  <div id="cancel-group">
    <form-group-section :element-key="'cancel'" :section-label="'Cancel Request'">
      <Transition name="complete-slide">
        <div v-if="!cancelSuccess" class="cancel">
          <cancellation-request-block
            element-key="cancel-request"
            :request="cancelRequest"
            :meta="cancelMeta"
            @onInput="inputHandler"
            @onMeta="metaHandler"
          ></cancellation-request-block>
          <div class="cancel-submission">
            <div v-if="cancelRequestLoading" class="loading-spinner"></div>
            <button
              id="cancel-request-submit-button"
              v-else
              class="wl-button"
              @click="submitRequest"
              :disabled="!cancelRequestIsValid"
            >
              Submit Request
            </button>
          </div>
        </div>
        <div v-else class="cancel-submitted">
          <div class="cancel-complete-message">
            <h2>Cancel request submitted!</h2>
            <fa-icon icon="fa-solid fa-check" class="check-beat wiggle-animation"></fa-icon>
          </div>
          <div class="complete-text">
            Your cancel request has been submitted. Please check your authorization status as it may
            take some time for the payer to respond.
          </div>
          <button
            id="cancellation-back-to-dashboard-button"
            class="ms-button"
            @click="completeButtonHandler"
          >
            Back to the Status Dashboard
          </button>
        </div>
      </Transition>
    </form-group-section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch, ref } from 'vue'
import { FormGroupSection } from '@/components/shared'
import { CancellationRequestBlock } from '@/components/wizard/blocks'
import { CancellationModels } from '@/models/cancellation'
import { WizardStateModels } from '@/models/store'
import { useStore } from 'vuex'
import { getMyndviewUrl } from '@/utils'

export default defineComponent({
  name: 'AuthCancellationSection',
  components: { CancellationRequestBlock, FormGroupSection },
  setup() {
    const store = useStore()
    const cancelRequest = computed(() => store.getters['cancellation/getCancellationState'])
    const cancelMeta = computed(() => store.getters['wizard/getCancellationSectionMeta'])
    const cancelRequestIsValid = computed(() => store.getters['wizard/isCancellationValid'])
    const cancelRequestLoading = computed(() => store.getters['wizard/isCancellationLoading'])
    const submissionId = computed(() => store.getters['getSubmissionId'])
    const status = computed(() => store.getters['summary/getSummaryStatus'])
    const cancelSubmitted = ref(false)
    const cancelSuccess = ref(false)

    const inputHandler = (request: CancellationModels.CancelRequest) => {
      store.commit('cancellation/setReason', request.reason)
      store.commit('cancellation/setPatientHasBeenNotified', request.patientHasBeenNotified)
      store.commit('cancellation/setAdditionalNotes', request.additionalNotes)
    }

    const metaHandler = (meta: WizardStateModels.WizardCancellationRequestMeta) => {
      store.commit('wizard/setCancellationRequestMeta', {
        ...meta,
        case: { ...cancelMeta.value?.case },
      })
    }

    const completeButtonHandler = () => {
      const url = `${getMyndviewUrl(document.location.origin)}/pa-status`
      window.location.replace(url)
    }

    const submitRequest = () => {
      store.commit('wizard/setIsCancellationLoading', true)
      store
        .dispatch('cancellation/cancelRequest')
        .then((success: boolean) => {
          cancelSubmitted.value = true
          cancelSuccess.value = success
        })
        .finally(() => {
          store.commit('wizard/setIsCancellationLoading', false)
        })
    }

    watch(submissionId, () => store.dispatch('summary/getSummaryDetails'))
    watch(status, status => {
      if (status?.caseNumber) {
        store.commit('cancellation/setCase', status.caseNumber)
        store.commit('wizard/setCancellationRequestMeta', {
          case: { ...cancelMeta.value.case, valid: !!status.caseNumber, dirty: true },
        })
      }
    })

    return {
      cancelMeta,
      cancelRequest,
      cancelRequestLoading,
      cancelRequestIsValid,
      cancelSubmitted,
      cancelSuccess,

      completeButtonHandler,
      inputHandler,
      metaHandler,
      submitRequest,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';

.cancel {
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 0 30px 20px 0;
}

.cancel-submission {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.loading-spinner {
  margin-right: 75px;
}

.check-beat {
  color: $myndshft-dark-green;
}

.cancel-submitted {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: $myndshft-light-black;
}

.cancel-complete-message {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.complete-text {
  display: flex;
  width: 60%;
  padding: 20px;
}

.complete-slide-enter-active {
  transition: all 0.5s ease-in 0.8s;
  .question {
    display: block;
    position: absolute;
  }
}
.complete-slide-leave-active {
  transition: opacity 0.4s ease 0.4s;
  .question {
    display: block;
    position: absolute;
  }
}
.complete-slide-enter-from {
  opacity: 0;
  transform: translateX(100px);
  .question {
    display: block;
    position: absolute;
  }
}
.complete-slide-leave-to {
  opacity: 0;
  .question {
    display: block;
    position: absolute;
  }
}
</style>
